<template>
  <div class="home_wrap">

    <div class="content">

      <div class="card">

        <div class="title">
          {{title}}
        </div>

        <div class="time">
          {{time}}
        </div>

        <mavon-editor v-model="value"
                      class="mavon-editor_warp"
                      :language="'zh-CN'"
                      :editable="false"
                      :toolbars-flag="false"
                      :subfield="false"
                      :default-open="'preview'"
        ></mavon-editor>

      </div>

    </div>

  </div>
</template>

<script>
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      BASE_URL: 'https://terms.jiaxinzp.com/api/',
      // BASE_URL: 'http://localhost:9001/api/',
      //     api/terms-list/

      title: '',
      time: '',
      value: '',

    }
  },
  mounted() {
    let type = this.$route.query.type

    if (type) {
      let url = this.BASE_URL + type + '/'
      this.loadData(url)
    }

  },
  methods: {
    async loadData(url) {
      const res = await this.get(url, {})
      console.log(res)
      if (res.data.code === 200) {
        if (res.data.data) {
          this.title = res.data.data.title
          this.value = res.data.data.content
          this.time = res.data.data.update_time
        }
      }
    },
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    get(url, params){
      return new Promise((resolve, reject) =>{
        this.$axios.get(url, {
          params: params
        })
            .then(res => {
              resolve(res)
            }).catch(err =>{
          reject(err)
        })
      })
    },

    /**
     * post方法，对应post请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    post(url, params) {
      return new Promise((resolve, reject) => {
        this.$axios.post(url, QS.stringify(params))
            .then(res => {
              resolve(res)
            })
            .catch(err =>{
              reject(err)
            })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.home_wrap {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
  .content {
    width: 880px;
    margin: 0 auto;
    padding: 25px 0 55px 0;
    box-sizing: border-box;

    .card {
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 32px 32px;
      box-sizing: border-box;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #111;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .time {
        font-size: 14px;
        color: #999;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
      }

      .mavon-editor_warp {
      }
    }

  }
}
</style>
